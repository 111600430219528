<template>

    <img style="width: 102px; height: auto" :src="`${this.url}/static/img/banks/LHVBEE22.svg`" alt="LHV logo">
</template>

<script>
export default {
    name: "icon-lhv",
    data() {
      return {
        url: null
      }
    },
    mounted() {
      this.url = process.env.VUE_APP_AS_API_URL
    }
};
</script>

<style scoped></style>
