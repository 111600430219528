<template>
    <div class="asc-lhv-modal-content-inner" id="inputContainer">
        <div v-if="['SID', 'MID', 'BIO', 'PIN'].includes(authMethod)">
            <asc-input
                class="asc-lhv-modal-content-inner--input"
                :class="{ 'has-error': user.errors && Object.keys(user.errors).includes('username') }"
                icon="person"
                @click="handleInputClickAndScroll"
                :placeholder="t('Username')"
                :value="user.username"
                inputId="lhvUsername"
                @inputChanged="handleUserValueChange($event, 'username')"
            />
            <asc-input
                class="asc-lhv-modal-content-inner--input"
                icon="lock"
                @click="handleInputClickAndScroll"
                :class="{ 'has-error': user.errors && Object.keys(user.errors).includes('ssn') }"
                :placeholder="t('Personal code')"
                :value="user.ssn"
                inputId="lhvSsn"
                @inputChanged="handleUserValueChange($event, 'ssn')"
                v-if="['SID', 'BIO'].includes(authMethod)"
            />
            <asc-input
                class="asc-lhv-modal-content-inner--input"
                icon="lock"
                @click="handleInputClickAndScroll"
                :class="{ 'has-error': user.errors && Object.keys(user.errors).includes('pin') }"
                :placeholder="t('PIN')"
                :value="user.ssn"
                inputId="lhvPin"
                @inputChanged="handleUserValueChange($event, 'pin')"
                v-if="['PIN'].includes(authMethod)"
            />
            <asc-input
                class="asc-lhv-modal-content-inner--input"
                icon="phone"
                @click="handleInputClickAndScroll"
                :class="{ 'has-error': user.errors && Object.keys(user.errors).includes('phone') }"
                :placeholder="t('Phone number')"
                :value="user.phone"
                inputId="lhvPhone"
                @inputChanged="handleUserValueChange($event, 'phone')"
                v-else-if="authMethod === 'MID'"
                type="tel"
            />
        </div>
        <div v-else>
            <p>
                {{
                    t(
                        "To authenticate with ID card, you will be redirected to LHV. Please follow the instructions shown in the steps"
                    )
                }}
            </p>
        </div>
    </div>
</template>
<script>
import AscInput from "@/components/asc-input.vue";
import { mapMutations, mapState } from "vuex";
import {translateMixin} from "@/mixins/translateMixin";

export default {
    name: "lhv-insert-user-info",
    components: { AscInput },
    mixins: [translateMixin],
    computed: {
        ...mapState("lhv", ["user", "authMethod"])
    },
    methods: {
        ...mapMutations("lhv", ["setUser"]),
        handleUserValueChange(value, prop) {
            this.setUser({ prop: prop, value: value });
        },
        handleInputClickAndScroll(e) {
            try {
                window.scrollTo({
                  top: e.pageY ? e.pageY : (e.y || 0),
                  behavior: "smooth",
                });
            } catch (e) {
                console.error(e)
            }
        }
    }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/variables";

.asc-lhv-modal-content-inner {
    width: 100%;

    &--input {
        margin-bottom: 8px;
    }
}
</style>
