<template>
    <div class="asc-components-successful">
        <div>
            <icon-success :color="color" width="40px" />
        </div>
        <h1>{{ t("Payment successful") }}!</h1>
        <p>
            {{
                t(
                    "Now you can exit payment modal."
                )
            }}.
        </p>
    </div>
</template>
<script>
import IconSuccess from "../../components/icons/icon-success.vue";
import {translateMixin} from "@/mixins/translateMixin";

export default {
    props: ["color"],
    name: "content-successful",
    components: { IconSuccess },
    mixins: [translateMixin]
};
</script>
<style scoped lang="scss">
.asc-components-successful {
    width: 100%;

    h1 {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #233238;
    }

    p {
        font-size: 15px;
        line-height: 19px;
        color: #233238;
    }
}
</style>
