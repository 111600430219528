
import {mapActions, mapMutations, mapState} from "vuex";
import Store from "../store/index";
import {defineComponent} from "vue";
import settings from "@/settings";
import {uiConfigurationsMixin} from "@/mixins/uiConfigurationsMixin";
import SwedbankMain from "@/components/embeded/swedbank-main.vue";
import LhvMain from "@/components/embeded/lhv-main.vue";

export default defineComponent({
    name: "tarkpay-bulk-payment-redirect",
    components: {LhvMain, SwedbankMain},
    mixins: [uiConfigurationsMixin],
    data(): any {
        return {
            swedbankEmbeded: false,
            lhvEmbeded: false,
            api_url: settings.api_url,
            strings: {
                code: this.t("Code"),
                next: this.t("Proceed"),
                error_happened: this.t("There was an error, please try again"),
                payment_done: this.t("Payment successfully completed!"),
                choose_account: this.t("Choose account"),
                close: this.t("Close"),
                sign: this.t("Confirm the payment"),
                name: this.t("Name"),
                smart_id: this.t("Smart ID"),
                mobile_id: this.t("Mobile ID"),
                choose_auth_method: this.t("Choose authentication method")
            },
            // @ts-ignore
            is_modal: TarkPay.is_modal,
            bank: null,
            assets_url: settings.assets_url,
            site_url: settings.api_url,
            selected_iban: null,
            selected_name: null,
            selected_currency: null,
            show_status: false,
            show_accounts: false,
            show_error: false,
            payment_id: null,
            auth_timeout: null,
            swedbankSwifts: [
                "SANDEE2X",
                "SANDSESS",
                "SANDLT22",
                "SANDLV22",
                "SWEDSESS",
                "HABAEE2X",
                "HABALV22",
                "HABALT22"
            ],
            sebSwifts: ["UNLALV2X", "EEUHEE2X", "CBVILT2X"],
            lhvSwifts: ["LHVBEE22"],
            coopSwifts: ["EKRDEE22"],
            luminorSwifts: ["NDEAEE2X", "RIKOLV2X", "AGBLLT2X"],
            waitingForConfirmationCounter: 0,
        };
    },
    async mounted() {
        Store.commit("startLoader");
        // @ts-ignore
        this.payment_id = window.TarkPay.payment_id;
        await this.fetchBulkPaymentDataFormDatabase();
        if (this.step === "wait-for-status") {
            await this.openModal();
            await Store.commit("startLoader");
            this.auth_timeout = setTimeout(this.pingForBulkPaymentStatus, 7000);
            return;
        }
        if (['finished', 'partial'].includes(this.step)) {
            this.openModal();
            this.show_status = true;
            window.localStorage.removeItem("demo-payment-key");
        }
        if (this.step === "psd2-show-accounts") {
            this.openModal();
            this.show_accounts = true;
            if (this.payment.accounts && this.payment.accounts.length > 0) {
                if (this.payment.accounts.length === 1) {
                    this.selected_iban = this.payment.accounts[0].iban;
                    Store.dispatch("getPaymentLink", {iban: this.selected_iban, type: 'bulk'}).then(result => {
                        this.show_accounts = false;
                        if (result) {
                            if (result.data.success === 1) {
                                window.location = result.data.href;
                                this.setHasNoError();
                            } else {
                                Store.commit("setTransactionStep", "psd2-show-accounts");
                                Store.commit("stopLoader");
                            }
                        } else {
                            this.subflow = "swedbank-error";
                            this.code = null;
                        }
                    });
                } else {
                    if (this.payment.accounts) this.selected_iban = this.payment.accounts[0].iban;
                }
            }
        }
        if (this.step === 'choosed-embedded-swedbank') {
            this.swedbankEmbeded = true;
        }
        if (this.step === 'choosed-embedded-lhv') {
            this.lhvEmbeded = true;
        }
        if (!this.step && !this.payment) {
            this.show_error = true;
        }
        let is_bulk_payment = true;
        await this.getBanksIcons(is_bulk_payment);
        await this.setCorrectCountryOnMounted()
    },
    watch: {
        selected_iban: function () {
            this.selected_name = null;
            this.selected_currency = null;
            let selected = this.accounts.find(
                    (account: { iban: string }): boolean => account["iban"] === this.selected_iban
            );
            if (selected) this.selected_name = selected;
            //if (selected.currency) this.selected_currency = selected.currency;
        }
    },
    beforeUnmount() {
        this.closeThisModal();
        if (this.auth_timeout) clearTimeout(this.auth_timeout);
    },
    unmounted() {
        this.closeThisModal();
    },
    computed: {
        ...mapState([
            "locale_code",
            "payment",
            "modal_open",
            "accounts",
            "step",
            "bank_swift",
            "show_loading",
            "bulk_banks_by_country",
            "default_country",
            "payment_key",
            "bulk_payments"
        ])
    },
    methods: {
        ...mapMutations(["openModal", "closeModal", "setHasNoError"]),
        ...mapActions([
            "getBanks",
            "fetchBulkPaymentDataFormDatabase",
            "getPaymentLink",
            "fetchTranslations",
            "pingBulkPaymentStatusResult"
        ]),
        async resetEmbedFlow() {
            this.swedbankEmbeded = false;
            this.lhvEmbeded = false;
            await this.fetchBulkPaymentDataFormDatabase()
            await this.getBanksIcons();
            await this.setCorrectCountryOnMounted()
        },
        embedBankDone() {
        },
        closeThisModal() {
            if (window.localStorage.getItem("demo-payment-key")) {
                window.localStorage.removeItem("demo-payment-key");
            }
            this.closeModal();
        },
        async userClickedPayButton() {
            await Store.commit("startLoader");
            let result = await this.getPaymentLink({iban: this.selected_iban, type: 'bulk'});
            if (result) {
                if (result.data.success === 1) {
                    this.setHasNoError();
                    window.location = result.data.href;
                } else {
                    Store.commit("setTransactionStep", "psd2-show-accounts");
                }
                Store.commit("stopLoader");
            } else {
                this.subflow = "swedbank-error";
                this.code = null;
                Store.commit("stopLoader");
            }
            await this.fetchBulkPaymentDataFormDatabase();
        },
        async getBanksIcons(is_bulk_payment=false) {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            if (params.get("success")) {
                // @ts-ignore
                window.history.replaceState(null, null, window.location.pathname);
            }
            let banks_response = await this.getBanks(is_bulk_payment);
            if (banks_response && banks_response.data && banks_response.data.default_country) {
                await Store.commit("setDefaultCountry", banks_response.data.default_country);
            }
            if (banks_response && banks_response.data && banks_response.data.bulk_banks_by_country) {
                await Store.commit("setBulkPaymentBanksByCountry", banks_response.data.bulk_banks_by_country);
            }
        },
        switchLang(locale: string): void {
            this.fetchTranslations(locale);
        },
        t(key: string): string {
            // @ts-ignore
            return window.TarkPay.translate(key);
        },
        async changeCountry(e: any) {
            await Store.commit('setDefaultCountry', e.target.value);
        },
        async setCorrectCountryOnMounted() {
            if (Object.keys(this.bulk_banks_by_country).length > 0 && !Object.keys(this.bulk_banks_by_country).includes(this.default_country)) {
                await Store.commit('setDefaultCountry', Object.keys(this.bulk_banks_by_country)[0]);
            }
            if (this.default_country && document.querySelector('#countrySelect')){
                // @ts-ignore
                document.querySelector('#countrySelect').value = this.default_country;
            }
        },
        getCountryName(country: string) {
            if (country.toUpperCase() === 'EE') return this.t('Estonia');
            else if (country.toUpperCase() === 'LV') return this.t('Latvia');
            else if (country.toUpperCase() === 'LT') return this.t('Lithuania');
            else return country;
        },
        getBulkPaymentTotalAmount(payments: []): number {
            let amount = 0;
            // @ts-ignore
            payments.forEach(p => amount += p.amount);
            return amount;
        },
        async pingForBulkPaymentStatus() {
            this.waitingForConfirmationCounter++;
            let result = await this.pingBulkPaymentStatusResult();
            await clearTimeout(this.auth_timeout);
            if (result.data.success === 1 && (["ACSC", "PART"].includes(result.data.status) || (['ACCC'].includes(result.data.status) && result.data.swift === "EKRDEE22"))) {
                // @ts-ignore
                window.location = `${process.env.VUE_APP_URL}/app/psd2/return-bulk-payment-transaction/?state=${this.payment_key}`;
            } else if (result.data.success === 1) {
                // @ts-ignore
                if (["CANC", "RJCT"].includes(result.data.status)) {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}/app/psd2/return-bulk-payment-transaction/?nok=1&state=${this.payment_key}`;
                }
                this.auth_timeout = setTimeout(this.pingForBulkPaymentStatus, 10000);
            } else if (result.data.success === 0) {
                // @ts-ignore
                window.location = `${process.env.VUE_APP_URL}/app/psd2/return-bulk-payment-transaction/?nok=1&state=${this.payment_key}`;
            }
        },
        getRedirectUrlFormatted() {
            if (!this.payment || !this.payment.redirect_url) {
                return this.t("site");
            }
            const domain = new URL(this.payment.redirect_url);
            return domain.hostname ? domain.hostname : this.t("site");
        },
        getWaitingForConfirmationTxt() {
            if (this.waitingForConfirmationCounter > 1 && this.waitingForConfirmationCounter < 7) {
                return this.t('This is taking longer than expected, please wait')
            } else if (this.waitingForConfirmationCounter >= 7) {
                return this.t('Please be patient. It is taking longer than expected for the bank to check the payment status')
            }

            return this.t('Waiting for confirmation')
        }
    }
});
