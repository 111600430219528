<template>
    <button @click="handleClick" class="asc-component-btn"><slot /></button>
</template>

<script>
export default {
    name: "asc-button",
    methods: {
        handleClick(e) {
            this.$emit("clickBtn", e);
        }
    }
};
</script>

<style scoped lang="scss">
$bankBtnTextColor: white;
@import "../assets/scss/mixins";
@import "../assets/scss/variables";

button {
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 42px;
    font-family: "Arial", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: white;
    min-width: 120px;
    background: $defaultBtnBackgroundColor;
    @include border-radius(42px);
    @include transition("background 100ms ease-in-out, transform 50ms ease");
}
button:hover,
button:focus {
    opacity: 0.6;
}

button:focus {
    border: 2px solid #c5c5c5;
}

button:active {
    transform: scale(0.99);
}
</style>
