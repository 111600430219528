<template>
    <div class="asc-component-information">
        <p>
            <slot/>
        </p>
        <p v-if="code" class="asc-component-information--code">{{ code }}</p>

        <div class="asc-component-information-payment-content"
             v-if="showPaymentData && paymentType === sc.PAYMENT_TYPE_REDIRECT">
            <div class="asc-component-information-payment-content-row">
                <div class="asc-component-information-payment-content-col">{{ t("Recipient name") }}</div>
                <div class="asc-component-information-payment-content-col">{{
                        payment ? payment.recipient_name : "-"
                    }}
                </div>
            </div>
            <div class="asc-component-information-payment-content-row">
                <div class="asc-component-information-payment-content-col">{{ t("Amount") }}</div>
                <div class="asc-component-information-payment-content-col">
                    {{ payment ? parseFloat(payment.amount).toFixed(2) : "-" }} EUR
                </div>
            </div>
            <div class="asc-component-information-payment-content-row">
                <div class="asc-component-information-payment-content-col">{{ t("Description") }}</div>
                <div class="asc-component-information-payment-content-col">{{
                        payment ? payment.description : "-"
                    }}
                </div>
            </div>
            <div class="asc-component-information-payment-content-row" v-if="payment && payment.reference_number">
                <div class="asc-component-information-payment-content-col">{{ t("Reference nr") }}</div>
                <div class="asc-component-information-payment-content-col">{{ payment.reference_number }}</div>
            </div>
            <div class="asc-component-information-payment-content-table" v-if="payment && payment.frequency">
                <div class="asc-component-information-payment-content-row">
                    <div class="asc-component-information-payment-content-col">{{ t("Frequency") }}</div>
                    <div class="asc-component-information-payment-content-col">{{ t(payment.frequency) }}</div>
                </div>
            </div>

            <div class="asc-component-information-payment-content-row" v-if="payment && payment.start_date">
                <div class="asc-component-information-payment-content-col">{{ t("Start date") }}</div>
                <div class="asc-component-information-payment-content-col">{{ payment.start_date }}</div>
            </div>

            <div class="asc-component-information-payment-content-row" v-if="payment && payment.end_date">
                <div class="asc-component-information-payment-content-col">{{ t("End date") }}</div>
                <div class="asc-component-information-payment-content-col">{{ payment.end_date }}</div>
            </div>
        </div>
        <div class="asc-component-information-payment-content"
             v-if="showPaymentData && paymentType === sc.PAYMENT_TYPE_BULK_PAYMENT">
            <div class="asc-component-information-payment-content-row">
                <div class="asc-component-information-payment-content-col">{{ t("Total") }}</div>
                <div class="asc-component-information-payment-content-col">
                    {{ payment ? parseFloat(payment.amount).toFixed(2) : "-" }} EUR
                </div>
            </div>
        </div>
        <div class="asc-component-information-accounts" v-if="showAccountsData">
            <div class="asc-component-information-accounts__text">{{
                    accounts.length > 1 ? t("Choose an account") : t("Payment will be made from the following account")
                }}:
            </div>
            <select id="accountSelect" v-if="accounts.length > 1" v-model="selAccount"
                    class="asc-component-information-accounts__select" :style="{'border-color': color ? color : null}">
                <option v-for="account in accounts"
                        :key="`iban_${account.iban}`"
                        :value="account.iban">
                    {{ getAccountName(account) || '-' }}
                </option>
            </select>
            <div v-else>{{ getAccountName(accounts[0]) }}</div>
        </div>
    </div>
</template>
<script>
import {translateMixin} from "@/mixins/translateMixin";

export default {
    name: "content-information",
    mixins: [translateMixin],
    data() {
        return {
        }
    },
    props: {
        code: {
            default: null,
            type: String,
        },
        showPaymentData: {
            default: false,
            type: Boolean
        },
        showAccountsData: {
            default: false,
            type: Boolean
        },
        color: {
            default: null,
            type: String
        },
        payment: {
            default: {},
            type: Object
        },
        accounts: {
            default: [],
            type: Array
        },
        selectedAccount: {
            default: null,
            type: String
        },
        paymentType: {
            default: null,
            type: String
        },
        sc: {
            default: {},
            type: Object
        }
    },
    computed: {
        selAccount: {
            get() {
                return this.selectedAccount;
            },
            set(newValue) {
                return this.$emit('setAccount', newValue);
            },
        },
    },
    methods: {
        getAccountName(account) {
            let name = '';

            if (!account) return name;

            if (account.iban) {
                name += account.iban;
            }

            if (account.name) {
                name += ` ${account.name}`
            }

            return name
        }
    }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.asc-component-information {
    width: 100%;

    &--code {
        font-weight: 700;
        font-size: 54px;
        line-height: 62px;
        color: $titleTextColor;
        margin: 0;
    }

    &-payment-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-row {
            display: flex;
            flex-direction: row;
        }

        &-col {
            padding: 6px 0;

            &:first-child {
                text-align: right;
                padding-right: 16px;
                min-width: 160px;
            }

            &:last-child {
                word-wrap: anywhere;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    &-accounts {
        margin-top: 24px;

        &__text {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;
        }

        &__select {
            margin-bottom: 18px;
            display: block;
            width: 100%;
            padding: 9px 36px 9px 14px;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            color: $defaultTextColor;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 16px 12px;
            border: 1px solid $defaultTextColor;
            appearance: none;
            @include border-radius(6px);
        }
    }
}

@media only screen and (max-width: $smallMobileMQ) {

}
</style>
