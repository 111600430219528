import { createStore } from "vuex";
import axios, { AxiosResponse } from "axios";
import settings from "@/settings";
import SwedbankStore from "./modules/swedbankStore"
import LhvStore from "@/store/modules/lhvStore";

let uri = window.location.search.substring(1);
let params = new URLSearchParams(uri);

let coop_swifts: String[] = ["EKRDEE22"];
let luminor_swifts: String[] = ["NDEAEE2X", "RIKOLV2X", "AGBLLT2X"];
let swedbank_swifts: String[] = [
    "SANDEE2X",
    "SANDSESS",
    "SANDLT22",
    "SANDLV22",
    "SWEDSESS",
    "HABAEE2X",
    "HABALV22",
    "HABALT22"
];
let revolut_swifts: String[] = ["REVOGB21XXXEE", "REVOGB21XXXLV", "REVOGB21XXXLT"];
let n26_swifts: String[] = ["N26EE", "N26LV", "N26LT"];

let seb_swifts: String[] = ["UNLALV2X", "EEUHEE2X", "CBVILT2X"];
let lhv_swifts: String[] = ["LHVBEE22"];
export default createStore({
    modules: {
        swedbank: SwedbankStore,
        lhv: LhvStore,
    },
    state: {
        redirect_modal_open: false,
        modal_open: false,
        ui_disabled: false,
        show_loading: false,
        has_error: false,
        has_success: false,
        jwt: params && params.get("asc-j") ? params.get("asc-j") : null,
        translations: {},
        accounts: [],
        transaction_status: null,
        step: null,
        bank_swift: null,
        bank_name: null,
        decoupled_banks: [],
        redirect_direct_to_modal: null,
        redirect_uri: null,
        payment_key: null,
        payment: null,
        locale_code: null,
        banks: [],
        redirect_banks_by_country: {},
        default_country: 'EE',
        bulk_payments: [],
        bulk_banks_by_country: {},
        uiConfigurations: {},
    },
    mutations: {
        openModal(state) {
            // @ts-ignore
            if (TarkPay.is_modal) {
                document.documentElement.style["overflow"] = "hidden";
            }
            console.log("Changed state to open");
            state.modal_open = true;
        },
        closeModal(state) {
            state.modal_open = false;
            document.documentElement.style["overflow"] = "auto";
            // @ts-ignore
            if (TarkPay.onClose) {
                // @ts-ignore
                TarkPay.onClose.call();
            }
        },
        setUiDisabled(state) {
            state.ui_disabled = true;
        },
        setUiEnabled(state) {
            state.ui_disabled = false;
        },
        addAccounts(state, payload) {
            state.accounts = payload;
        },
        transactionStatus(state, payload) {
            state.transaction_status = payload;
        },
        startLoader(state) {
            state.show_loading = true;
        },
        stopLoader(state) {
            state.show_loading = false;
        },
        setHasError(state) {
            state.has_error = true;
        },
        setHasNoError(state) {
            state.has_error = false;
        },
        addTranslations(state, args) {
            state.translations = args.translations;
        },
        setHasSuccess(state, val) {
            state.has_success = val;
        },
        setJwt(state, val) {
            state.jwt = val;
        },
        setRedirectDirectToModal(state, val) {
            state.redirect_direct_to_modal = val;
        },
        setTransactionStep(state, val) {
            state.step = val;
        },
        setBankSwift(state, val) {
            state.bank_swift = val;
        },
        setBankName(state, val) {
            state.bank_name = val;
        },
        setBanks(state, val) {
            state.banks = val;
        },
        setBulkPayments(state, val) {
            state.bulk_payments = val;
        },
        setUiConfigurations(state, val) {
            state.uiConfigurations = val;
        },
        setRedirectBanksByCountry(state, val) {
            state.redirect_banks_by_country = val;
        },
        setBulkPaymentBanksByCountry(state, val) {
            state.bulk_banks_by_country = val;
        },
        setDefaultCountry(state, val) {
            state.default_country = val;
        },
        setDecoupledBanks(state, val) {
            state.decoupled_banks = val;
        },
        setRedirectURI(state, val) {
            state.redirect_uri = val;
        },
        setPaymentKey(state, val) {
            state.payment_key = val;
        },
        setPayment(state, val) {
            state.payment = val;
        },
        setLocale(state, locale) {
            state.locale_code = locale;
        }
    },
    getters: {
        bankSwift: state => {
            return state.bank_swift;
        }
    },
    actions: {
        onCloseModal({ commit }) {
            commit("closeModal");
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            commit("setTransactionStep", "loading");
            if (uri.length > 0) {
                // @ts-ignore
                window.history.replaceState(null, null, window.location.pathname);
            }
            // @ts-ignore
            if (TarkPay.onClose) {
                // @ts-ignore
                TarkPay.onClose.call();
            }
        },
        async getBanks({ commit, state }, is_bulk_payment) {
            commit("startLoader");
            let url = settings.api_url + "/api/embed/v1/banks/";
            try {
                if (this.state.jwt) {
                    axios.defaults.headers["X-Jwt"] = this.state.jwt;
                }
                let response = await axios.get(url, {
                    params: {
                        // @ts-ignore
                        client_id: window.TarkPay.client_id,
                        requested_from: encodeURIComponent(window.location.href),
                        payment_key: state.payment_key ? state.payment_key : null,
                        bulk_payment: is_bulk_payment
                    }
                });

                if (response.headers["x-jwt"]) {
                    commit("setJwt", response.headers["x-jwt"]);
                }
                commit("stopLoader");
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
                return response;
            } catch (error) {
                commit("stopLoader");
                commit("setHasError");
                console.error(error);
                return false;
            }
        },
        async redirectToLuminorLogin({ commit, state }) {
            commit("setUiDisabled");
            commit("startLoader");
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            let url = settings.api_url + "/api/v1/embed/get-luminor-oauth-token/";
            let response = await axios.get(url);
            if (response.headers["x-jwt"]) {
                commit("setJwt", response.headers["x-jwt"]);
            }
            axios.defaults.headers["X-Jwt"] = this.state.jwt;
            commit("setUiEnabled");
            return response;
        },
        async startAuthorizing({ commit, state }, bank) {
            if (state.ui_disabled) {
                return;
            }
            commit("setUiDisabled");
            commit("startLoader");
            let url = settings.api_url + "/api/v1/embed/start-authorizing/";
            let response = await axios.post(url, {
                bank: bank,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            });
            commit("setUiEnabled");
            return response;
        },
        async startLuminorFBAuthorize({ commit, state }, args) {
            commit("setUiDisabled");
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/start-luminor-authorization/";
            let result = await axios.post(url, args);
            if (result.headers["x-jwt"]) {
                commit("setJwt", result.headers["x-jwt"]);
            }
            axios.defaults.headers["X-Jwt"] = this.state.jwt;
            commit("setUiEnabled");
            return result;
        },
        async proceedLuminorAuthorize({ commit, state }) {
            commit("setUiDisabled");
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            let url = settings.api_url + "/api/v1/embed/continue-luminor-authorization/";
            let result: AxiosResponse | {} = {};
            try {
                result = await axios.post(url, {
                    // @ts-ignore
                    client_id: window.TarkPay.client_id
                });
            } catch {
                commit("setHasError");
            }
            // @ts-ignore
            if (result.headers["x-jwt"]) {
                // @ts-ignore
                commit("setJwt", result.headers["x-jwt"]);
            }
            axios.defaults.headers["X-Jwt"] = this.state.jwt;
            commit("stopLoader");
            // @ts-ignore
            if (result.data.success === 1) {
                // @ts-ignore
                commit("addAccounts", result.data.total_accounts);
            }

            commit("setUiEnabled");
            return result;
        },
        async selectLuminorCustomer({ commit, state }, args) {
            commit("setUiDisabled");
            let url = settings.api_url + "/api/v1/embed/select-luminor-customer/";
            let result;
            try {
                result = await axios.post(url, {
                    customer: args.customer,
                    // @ts-ignore
                    client_id: window.TarkPay.client_id
                });
            } catch {
                commit("setHasError");
            }

            commit("setUiEnabled");
            return result;
        },
        async fetchLuminorAccounts({ commit, state }) {
            commit("setUiDisabled");
            let url = settings.api_url + "/api/v1/embed/fetch-luminor-accounts/";
            try {
                let result = await axios.post(url, {
                    // @ts-ignore
                    client_id: window.TarkPay.client_id
                });
                if (result.data.success === 1) {
                    commit("addAccounts", result.data.accounts);
                }
                return result;
            } catch {
                commit("setHasError");
            }
            commit("setUiEnabled");
        },
        async startLuminorDecoupledPaymentConfirmation({ commit, state }, args) {
            commit("setUiDisabled");
            let url = settings.api_url + "/api/v1/embed/start-luminor-payment/";
            let result;
            try {
                result = await axios.post(url, {
                    iban: args.iban,
                    // @ts-ignore
                    client_id: window.TarkPay.client_id
                });
            } catch {
                commit("setHasError");
            }
            commit("setUiEnabled");
            return result;
        },
        async authorizeLuminorPayment({ commit, state }, args) {
            commit("setUiDisabled");
            let url = settings.api_url + "/api/v1/embed/authorize-luminor-payment/";
            let result;
            try {
                result = await axios.post(url, {
                    url: args,
                    // @ts-ignore
                    client_id: window.TarkPay.client_id
                });
            } catch {
                commit("setHasError");
            }
            commit("setUiEnabled");
            return result;
        },
        async startSwedbankAuthorize({ commit, state }, args) {
            //commit("setUiDisabled");
            //commit("startLoader");
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/start-swedbank-authorizing/";
            let result = await axios.post(url, args);
            //commit("setUiEnabled");
            //commit("stopLoader");
            return result;
        },
        async pingSwedbankAuthorizationResult({ commit, state }, args) {
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/ping-swedbank-authorizing/";
            return await axios.post(url, args);
        },
        async fetchSwedbankAccounts({ commit, state }, args) {
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/fetch-swedbank-accounts/";
            let result = await axios.post(url, args);
            return result;
        },
        async startPaymentConfirmation({ commit, state }, args) {
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/start-swedbank-payment-authorizing/";
            let result = await axios.post(url, args);
            return result;
        },
        async pingSwedbankPaymentAuthorizationResult({ commit, state }, args) {
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/ping-swedbank-payment-authorization/";
            let result = await axios.post(url, args);
            return result;
        },
        async startSingleSwedbankAccountConsentAuthorization({ commit, state }, args) {
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/start-single-swedbank-account-consent-authorization/";
            let result = await axios.post(url, args);
            return result;
        },
        async startSebConsentAuthorization({ commit, state }, args) {
            args = {
                ...args,
                // @ts-ignore
                client_id: window.TarkPay.client_id
            };
            let url = settings.api_url + "/api/v1/embed/start-seb-consent-authorization/";
            return await axios.post(url, args);
        },
        async pingSebConsentAuthorizationResult({ commit, state }) {
            let url = settings.api_url + "/api/v1/embed/ping-seb-consent-authorization/";
            return await axios.post(url);
        },
        async fetchSebDecoupledAccounts({ commit, state }) {
            let url = settings.api_url + "/api/v1/embed/fetch-seb-accounts/";
            return await axios.get(url);
        },
        async startSebDecoupledPaymentConfirmation({ commit, state }, args) {
            let url = settings.api_url + "/api/v1/embed/start-seb-payment-authorization/";
            return await axios.post(url, args);
        },
        async pingSebPaymentAuthorizationResult({ commit, state }, args) {
            let url = settings.api_url + "/api/v1/embed/ping-seb-payment-authorization/";
            return await axios.post(url, args);
        },

        async fetchTranslations({ commit, state }, locale) {
            let url = settings.assets_url + "/translations/" + locale + ".json";
            delete axios.defaults.headers["X-Jwt"];
            delete axios.defaults.headers["Client-ID"];
            let results = await axios.get(url, { withCredentials: false, headers: {} });
            // @ts-ignore
            axios.defaults.headers["Client-ID"] = TarkPay.client_id;
            // @ts-ignore
            window.localStorage.setItem(TarkPay.payment_id + "-tp-locale", locale);
            commit("setLocale", locale);
            commit("addTranslations", {
                translations: results.data
            });
        },
        async fetchDataFormDatabase({ commit }) {
            let url = settings.api_url + "/api/embed/v1/session-info/";
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            let result = await axios.post(url, {
                // @ts-ignore
                payment_token: TarkPay.payment_id,
                requested_from: encodeURIComponent(window.location.href),
                // @ts-ignore
                bulk_payment: TarkPay.bulk_payment,
            });
            if (result.headers["x-jwt"]) {
                commit("setJwt", result.headers["x-jwt"]);
            }
            if (result.data) {
                if (result.data.payment) {
                    commit("setPayment", result.data.payment);
                }
                if (result.data.step) {
                    commit("setTransactionStep", result.data.step);
                }
                if (result.data.swift) {
                    commit("setBankSwift", result.data.swift);
                }
                if (result.data.name) {
                    commit("setBankName", result.data.name);
                }
                if (result.data.payment_key) {
                    commit("setPaymentKey", result.data.payment_key);
                }
                if (result.data.banks) {
                    commit("setBanks", result.data.banks);
                }
                if (result.data.bulk_payments) {
                    commit("setBulkPayments", result.data.bulk_payments);
                }
                if (result.data.ui_configurations) {
                    commit("setUiConfigurations", result.data.ui_configurations);
                }
            }
        },
        async fetchBulkPaymentDataFormDatabase({ commit }) {
            let url = settings.api_url + "/api/embed/v1/bulk-payment-session-info/";
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            let result = await axios.post(url, {
                // @ts-ignore
                payment_token: TarkPay.payment_id,
                requested_from: encodeURIComponent(window.location.href),
            });
            if (result.headers["x-jwt"]) {
                commit("setJwt", result.headers["x-jwt"]);
            }
            if (result.data) {
                if (result.data.payment) {
                    commit("setPayment", result.data.payment);
                }
                if (result.data.step) {
                    commit("setTransactionStep", result.data.step);
                }
                if (result.data.swift) {
                    commit("setBankSwift", result.data.swift);
                }
                if (result.data.payment_key) {
                    commit("setPaymentKey", result.data.payment_key);
                }
                if (result.data.bulk_payments) {
                    commit("setBulkPayments", result.data.bulk_payments);
                }
                if (result.data.ui_configurations) {
                    commit("setUiConfigurations", result.data.ui_configurations);
                }
            }
        },
        async redirectToBankPageForAuthorization({ dispatch, commit, getters, state }) {
            await dispatch("fetchDataFormDatabase");
            let url = settings.api_url;
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            let result;
            if (coop_swifts.includes(getters.bankSwift)) {
                //COOP
                result = await axios.post(
                    settings.api_url + "/app/psd2/redirect/coop/authorize/?state=" + state.payment_key
                );
            } else {
                if (swedbank_swifts.includes(getters.bankSwift)) {
                    url += "/app/psd2/redirect/swedbank/authorize/";
                } else if (seb_swifts.includes(getters.bankSwift)) {
                    url += "/psd2/seb/authorize/";
                } else if (lhv_swifts.includes(getters.bankSwift)) {
                    url += "/app/psd2/redirect/lhv/authorize/";
                }
                url += "?state=" + state.payment_key;
                result = await axios.get(url);
                if (result.headers["x-jwt"]) {
                    commit("setJwt", result.headers["x-jwt"]);
                }
            }
            return result;
        },
        async getPaymentLink({ commit, state, dispatch, getters }, { iban, type }) {
            if (type && type === 'bulk'){
                await dispatch("fetchBulkPaymentDataFormDatabase");
            } else {
                await dispatch("fetchDataFormDatabase");
            }
            let url = settings.api_url;
            try {
                if (this.state.jwt) {
                    axios.defaults.headers["X-Jwt"] = this.state.jwt;
                }
                if (swedbank_swifts.includes(getters.bankSwift)) {
                    url += "/app/psd2/redirect/swedbank/payment/";
                } else if (seb_swifts.includes(getters.bankSwift)) {
                    url += `/app/psd2/${type === 'bulk' ? 'bulk-payment' : 'redirect'}/seb/payment/`;
                } else if (lhv_swifts.includes(getters.bankSwift)) {
                    url += `/app/psd2/${type === 'bulk' ? 'bulk-payment' : 'redirect'}/lhv/payment/`;
                } else if (coop_swifts.includes(getters.bankSwift)) {
                    url += `/app/psd2/${type === 'bulk' ? 'bulk-payment' : 'redirect'}/coop/payment/`;
                } else if (luminor_swifts.includes(getters.bankSwift)) {
                    url += "/app/psd2/redirect/luminor/payment/";
                } else if (revolut_swifts.includes(getters.bankSwift)) {
                    url += "/app/psd2/redirect/revolut/payment/";
                } else if (n26_swifts.includes(getters.bankSwift)) {
                    url += "/app/psd2/redirect/n26/payment/";
                }
                url += "?iban=" + iban + "&state=" + state.payment_key;
                if (seb_swifts.includes(getters.bankSwift) || n26_swifts.includes(getters.bankSwift)) {
                    await commit("startLoader")
                    return {"data": {"success": 1, "href": url}};
                }
                let result = await axios.get(url);
                if (result) {
                    let data = result.data;
                    if (result.headers["x-jwt"]) {
                        commit("setJwt", result.headers["x-jwt"]);
                    }
                    return result;
                }
            } catch (e) {
                console.log(e);
            }
        },
        async getFinishedTransactionStatus({ dispatch, commit, getters, state }) {
            let url = settings.api_url;
            await dispatch("fetchDataFormDatabase");
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            if (swedbank_swifts.includes(getters.bankSwift)) {
                url += "/app/psd2/redirect/swedbank/status/";
            } else if (seb_swifts.includes(getters.bankSwift)) {
                url += "/app/psd2/redirect/seb/status/";
            } else if (lhv_swifts.includes(getters.bankSwift)) {
                url += "/app/psd2/redirect/lhv/status/";
            } else if (coop_swifts.includes(getters.bankSwift)) {
                url += "/app/psd2/redirect/coop/status/";
            } else if (luminor_swifts.includes(getters.bankSwift)) {
                url += "/app/psd2/redirect/luminor/status/";
            }
            url += "?state=" + state.payment_key;
            let result = await axios.get(url);
            if (result) {
                let data = result.data;
                if (result.headers["x-jwt"]) {
                    commit("setJwt", result.headers["x-jwt"]);
                }
                if (data && data.success && data.success === 0) {
                    await dispatch("fetchDataFormDatabase");
                }
                if (result.status === 200) {
                    commit("transactionStatus", result.data.status);
                    commit("setRedirectURI", result.data.url);
                } else {
                    console.log(result);
                }
            }
            return result;
        },
        async deleteSession({ commit }) {
            let url = settings.api_url + "/api/embed/v1/delete-session-info/";
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            let result = await axios.post(url);
            if (result.headers["x-jwt"]) {
                commit("setJwt", result.headers["x-jwt"]);
            }
        },
        async pingCitadeleStatusResult({ state }) {
            let url = settings.api_url + "/app/psd2/redirect/citadele/status/?state=" + state.payment_key;
            return await axios.get(url);
        },
        async pingStatusResult({ state }) {
            let url = settings.api_url + "/app/psd2/redirect/statuses/?state=" + state.payment_key;
            return await axios.get(url);
        },
        async pingBulkPaymentStatusResult({ state }) {
            let url = `${settings.api_url}/app/psd2/redirect/statuses/?state=${state.payment_key}&is_bulk_payment=true`
            return await axios.get(url);
        },
        async pingN26PaymentStatusResult({ state }) {
            let url = settings.api_url + "/app/psd2/redirect/n26/payment/status/?state=" + state.payment_key;
            return await axios.get(url);
        },
        async pingN26ConsentStatusResult({ state }) {
            let url = settings.api_url + "/app/psd2/redirect/n26/consent/status/?state=" + state.payment_key;
            return await axios.get(url);
        },
        async createStripePayment({ commit, state }) {
            let url = settings.api_url + "/app/psd2/redirect/stripe/payment/?state=" + state.payment_key;
            if (this.state.jwt) {
                axios.defaults.headers["X-Jwt"] = this.state.jwt;
            }
            let result = await axios.post(url);
            if (result.headers["x-jwt"]) {
                commit("setJwt", result.headers["x-jwt"]);
            }
            return result;
        }
    }
});
