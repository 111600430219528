<template>
    <svg
        :width="width ? width : '56'"
        :height="width ? width : '56'"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.7031 36.3125C25.0469 36.9688 23.8438 36.9688 23.1875 36.3125L16.1875 29.3125C15.5312 28.6562 15.5312 27.4531 16.1875 26.7969C16.8438 26.1406 18.0469 26.1406 18.7031 26.7969L24.5 32.5938L37.1875 19.7969C37.8438 19.1406 39.0469 19.1406 39.7031 19.7969C40.3594 20.4531 40.3594 21.6562 39.7031 22.3125L25.7031 36.3125ZM56 28C56 43.5312 43.4219 56 28 56C12.4688 56 0 43.5312 0 28C0 12.5781 12.4688 0 28 0C43.4219 0 56 12.5781 56 28ZM28 3.5C14.4375 3.5 3.5 14.5469 3.5 28C3.5 41.5625 14.4375 52.5 28 52.5C41.4531 52.5 52.5 41.5625 52.5 28C52.5 14.5469 41.4531 3.5 28 3.5Z"
            :fill="color ? color : '#4DC200'"
        />
    </svg>
</template>

<script>
export default {
    props: ["color", "width"],
    name: "icon-success"
};
</script>

<style scoped></style>
