<template>
    <div class="asc-component-tabs" :class="{ 'asc-component-tabs__disabled': disabled }">
        <button
            v-for="item in authMethods"
            class="asc-component-tabs--tab"
            :class="{
                'asc-component-tabs--tab__active': authMethod === item.value,
                'asc-component-tabs--tab__error': authMethod === item.value && user && user.errors && user.errors.method,
            }"
            :style="{ color: authMethod === item.value ? color : null }"
            @click="changeAuthenticationMethod(item.value)"
        >
            {{ t(item.name) }}
        </button>
    </div>
</template>

<script>
import {translateMixin} from "@/mixins/translateMixin";

export default {
    props: ["disabled", "authMethod", "authMethods", "color", "user"],
    name: "content-tabs",
    mixins: [translateMixin],
    mounted() {
        if (!this.authMethod) {
            const smartId = this.authMethods.find(am => am.value === "SMART_ID");
            const authMode = smartId ? smartId.value : this.authMethods[0].value;
            this.changeAuthenticationMethod(authMode);
        }
    },
    methods: {
        changeAuthenticationMethod(method) {
            if (!this.disabled) {

                if (this.user && this.user.errors && this.user.errors.method) {
                   this.user.errors.method = false
                }

                this.$emit("changeTab", method);
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.asc-component {
    &-tabs {
        display: flex;
        justify-content: center;
        padding: 0 40px;
        border-bottom: 1px solid rgba(92, 105, 117, 0.12);

        button {
            color: #000;
            border: 0;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: none;
            font-weight: 300;
            font-size: 15px;
            line-height: 15px;
        }
        &__disabled {
            button {
                cursor: auto;
            }
        }

        &--tab {
            display: flex;
            flex-grow: 1;
            text-align: center;
            justify-content: center;
            padding: 20px 0;
            cursor: pointer;

            &__active {
                border-bottom: 3px solid !important;
                @include border-radius(3px);
            }

            &__error {
                color: red !important;
            }
        }
    }
}
</style>
