<template>
    <div class="asc-lhv">
        <div class="asc-lhv-logo">
            <icon-lhv />
        </div>
        <div class="asc-lhv-modal">
            <content-tabs
                :color="sc.COLOR"
                :disabled="step || loading"
                :auth-method="authMethod"
                @changeTab="changeAuthenticationMethod($event)"
                :authMethods="authMethods"
            />
            <div class="asc-lhv-modal-content" v-if="loading">
                <asc-loader :color="sc.COLOR" />
            </div>
            <div class="asc-lhv-modal-content" v-else>
                <content-failed v-if="step === sc.FAILED" />

                <content-successful :color="sc.COLOR" v-else-if="step === sc.SUCCESSFUL" />

                <lhv-select-role v-else-if="step === sc.SHOW_ROLES" :color="sc.COLOR" />

                <content-information v-else-if="step === sc.SHOW_TEXT"
                                     :code="code"
                                     :show-payment-data="!!(subStep && subStep === sc.ACCEPT_PAYMENT)"
                                     :show-accounts-data="!!(subStep && subStep === sc.ACCEPT_PAYMENT && accounts.length > 0)"
                                     :payment="payment"
                                     :accounts="accounts"
                                     :selected-account="selectedAccount"
                                     :payment-type="paymentType"
                                     :color="sc.COLOR"
                                     :sc="sc"
                                     @setAccount="handleSetSelectedAccountSelect">
                    {{ t(text) }}
                </content-information>

                <lhv-insert-user-info v-else />
            </div>
            <div
                class="asc-lhv-modal-actions"
                :class="{ 'asc-lhv-modal-actions__right': !step}"
                v-if="!loading && !code"
            >
                <asc-button @clickBtn="handleProceedClick" class="asc-lhv-modal-btn"
                    >{{ subStep === sc.ACCEPT_PAYMENT ? t('Pay') : t('Proceed') }}
                </asc-button>
            </div>
        </div>
    </div>
</template>
<script>
import IconLhv from "@/components/icons/icon-lhv.vue";
import AscButton from "@/components/asc-button.vue";
import AscInput from "@/components/asc-input.vue";
import ContentTabs from "@/components/content/content-tabs.vue";
import LhvInsertUserInfo from "@/components/lhv/lhv-insert-user-info.vue";
import ContentInformation from "@/components/content/content-information.vue";
import ContentSuccessful from "@/components/content/content-successful.vue";
import ContentFailed from "@/components/content/content-failed.vue";
import AscLoader from "@/components/asc-loader.vue";
import { mapActions, mapMutations, mapState } from "vuex";

import { lhvConstants } from "@/constants";
import {translateMixin} from "@/mixins/translateMixin";
import LhvSelectRole from "@/components/lhv/lhv-select-role.vue";

export default {
    name: "lhv-main",
    components: {
        IconLhv,
        AscButton,
        AscInput,
        ContentTabs,
        LhvInsertUserInfo,
        ContentInformation,
        ContentSuccessful,
        ContentFailed,
        AscLoader,
        LhvSelectRole
    },
    props: {
        isBulkPayment: {
            default: false,
            type: Boolean,
        },
        activeBank: {
            default: null,
            type: String,
        },
        clientCountry: {
            default: 'et_EE',
            type: String,
        },
        payment: {
            default: {},
            type: Object
        }
    },
    mixins: [translateMixin],
    data() {
        return {
            timeoutTimer: null,
            sc: lhvConstants
        };
    },
    async mounted() {
        // Both bulk-payment and regular payment use this component
        this.setPaymentType(this.isBulkPayment ? this.sc.PAYMENT_TYPE_BULK_PAYMENT : this.sc.PAYMENT_TYPE_REDIRECT);

        if (this.clientCountry === "lv_LV") await this.setUser({ prop: "phone", value: "+371" });
        else if (this.clientCountry === "lt_LT") await this.setUser({ prop: "phone", value: "+370" });
        this.addPaymentInfo(this.payment)
    },
    computed: {
        ...mapState("lhv", [
            "user",
            "step",
            "authMethod",
            "authMethods",
            "code",
            "loading",
            "text",
            "subStep",
            "accounts",
            "selectedAccount",
            "paymentType"
        ]),
    },
    methods: {
        ...mapMutations("lhv", [
            "setLoading",
            "setAuthMethod",
            "setUser",
            "setStep",
            "resetStates",
            "setSubStep",
            "setText",
            "addPaymentInfo",
            "setPaymentType",
            "setSelectedAccount"
        ]),
        ...mapActions("lhv", [
            "startLhvAuthorization",
            "pingLhvAuthorization",
            "fetchAccounts",
            "startPaymentAuthorization",
            "pingPaymentAuthorization",
            "handleRoleSelect"
        ]),
        ...mapActions("lhv", ["startBankAuthorizing"]),
        handleUserValueChange(value, prop) {
            this.setUser({ prop: prop, value: value });
        },
        changeAuthenticationMethod(method) {
            this.setAuthMethod(method);
        },
        async handleProceedClick() {
            let result = null;
            if (!this.step) {
                if (this.authMethod === "ID_CARD") {
                    this.setLoading(true);
                    const returnUrlPart = this.isBulkPayment ? 'bulk-payment' : 'payment'

                    window.location = `${process.env.VUE_APP_URL}/app/psd2/${returnUrlPart}/${window.TarkPay.payment_id}/${this.activeBank}?locale=${this.clientCountry}&ignore-embed=1`;

                    return;
                }
                //First time if lhv is clicked
                result = await this.startLhvAuthorization({ swift: this.activeBank });
            } else if (this.step === this.sc.SHOW_ROLES) {
                result = await this.handleRoleSelect({ swift: this.activeBank });

                if (result) {
                    await this.setLoading(true);
                    result = await this.fetchAccounts({swift: this.activeBank});
                }
                return;
            }else if (this.subStep === this.sc.ACCEPT_PAYMENT) {
                // After authorization, to start payment
                let { response, isCompleted } = await this.startPaymentAuthorization({ swift: this.activeBank });

                if (response && isCompleted) {
                    // Payment already ASCS
                    this.setLoading(true);
                    this.resetStates();
                    this.$emit("done");
                    const returnUrlPart = this.isBulkPayment ? 'return-bulk-payment-transaction' : 'redirect-transaction'

                    window.location = `${process.env.VUE_APP_URL}/app/psd2/${returnUrlPart}/?state=${window.TarkPay.payment_id}`;
                }

                result = response;

            } else if (this.step === this.sc.SUCCESSFUL) {
                this.resetStates();
                this.$emit("done");
                const returnUrlPart = this.isBulkPayment ? 'return-bulk-payment-transaction' : 'redirect-transaction'

                window.location = `${process.env.VUE_APP_URL}/app/psd2/${returnUrlPart}/?state=${window.TarkPay.payment_id}`;
            } else if (this.step === this.sc.FAILED) {
                // If something went wrong
                this.resetStates();
                this.$emit("resetFlow");
            }
            if (result) {
                this.timeoutTimer = setTimeout(() => this.pingForResult(this.subStep), 9000);
            }
        },
        async pingForResult(flow) {
            let response = null;

            if (flow === this.sc.AUTHORIZATION) {
                let { response: authResponse, hasRoles } = await this.pingLhvAuthorization({ swift: this.activeBank });
                response = authResponse

                if (response && !hasRoles) {
                    await this.setLoading(true);
                    response = await this.fetchAccounts({ swift: this.activeBank });
                }
            } else if (flow === this.sc.CONFIRM_PAYMENT) {
                response = await this.pingPaymentAuthorization({ swift: this.activeBank });
            }

            if (response) {
                clearTimeout(this.timeoutTimer);
                if (flow === this.sc.CONFIRM_PAYMENT) {
                    this.resetStates();
                    this.$emit("done");
                    const returnUrlPart = this.isBulkPayment ? 'return-bulk-payment-transaction' : 'redirect-transaction'

                    window.location = `${process.env.VUE_APP_URL}/app/psd2/${returnUrlPart}/?state=${window.TarkPay.payment_id}`;
                }
            } else {
                if (this.step !== this.sc.FAILED) {
                    this.timeoutTimer = setTimeout(() => this.pingForResult(flow), 2500);
                }
            }
        },
        handleSetSelectedAccountSelect(e) {
            this.setSelectedAccount(e);
        }
    }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.asc-lhv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Arial", serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    color: $defaultTextColor;

    &-bg {
        background: #fbf2eb;
    }
    &-logo {
        text-align: center;
        margin-bottom: 16px;
    }

    &-modal {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        background: #ffffff;
        min-height: 300px;
        width: 100%;
        padding-bottom: 40px;
        @include border-radius(12px);
        @include box-shadow(0 12px 34px rgba(0, 0, 0, 0.04));

        &-content {
            display: flex;
            align-items: center;
            padding: 40px 40px 24px 40px;
            text-align: center;
            flex-grow: 1;
        }

        &-actions {
            display: flex;
            justify-content: center;
            padding: 0 40px;

            button {
                background: $lhvColor;
                min-width: 220px;
            }

            &__right {
                justify-content: flex-end;
            }
        }
    }
}

@media only screen and (max-width: $smallMobileMQ) {
    .asc-lhv {
        &-modal {
            &-actions {
                &__right {
                    justify-content: center;
                }
            }
        }
    }
}
</style>
