import {createApp} from "vue";
import "core-js/stable";
import "regenerator-runtime/runtime";
// @ts-ignore
import store from './store/index.ts';
//import TarkPayModal from "./views/tarkpay-modal.vue";
import TarkPayRedirect from "./views/tarkpay-redirect.vue";
import TarkPayBulkPaymentRedirect from "./views/tarkpay-bulk-payment-redirect.vue";
import axios from "axios";


(function (TarkPay, undefined) {
    function injectTarkpayContainer() {
        let ascDiv: HTMLElement | null = document.createElement("div");
        if (TarkPay.is_modal) {
            if (!document.getElementById("tarkpay-embed-app")) {
                ascDiv = document.createElement("div");
                ascDiv.id = "tarkpay-embed-app";
                document.body.appendChild(ascDiv);
            } else {
                ascDiv = document.getElementById(TarkPay.container_id);
            }
        } else {
            ascDiv = document.getElementById(TarkPay.container_id);
        }
        let vueModalElement = document.createElement("tarkpay-modal");
        // @ts-ignore
        ascDiv.appendChild(vueModalElement);
    }

    TarkPay.destroy = function () {
        TarkPay.vueapp.unmount();
    };
    TarkPay.initialize = function (settingsObj: { redirect: boolean; bulk_payment: boolean; invitation_id: string; client_id: string; width: string; height: string; onClose: any; onAccountsFetched: any; onTransactionsFetched: null; locale: string; container_id: string; is_modal: boolean; payment_id: string; btn_id: string; }) {
        axios.defaults.withCredentials = true;
        setTimeout(function () {
            TarkPay.redirect = settingsObj.redirect;
            TarkPay.bulk_payment = settingsObj.bulk_payment || false;
            TarkPay.invitation_id = settingsObj.invitation_id;
            TarkPay.client_id = settingsObj.client_id;
            TarkPay.width = settingsObj.width || "100%";
            TarkPay.height = settingsObj.height || "100%";
            TarkPay.onClose = settingsObj.onClose || null;
            TarkPay.onAccountsFetched = settingsObj.onAccountsFetched || null;
            TarkPay.onTransactionsFetched = settingsObj.onTransactionsFetched || null;
            TarkPay.locale = settingsObj.locale || "et_EE";
            TarkPay.container_id = settingsObj.container_id || "tarkpay-embed-app";
            TarkPay.is_modal = settingsObj.is_modal !== undefined ? settingsObj.is_modal : true;
            TarkPay.payment_id = settingsObj.payment_id || null;
            if (!["et_EE", "ru_RU", "lv_LV", "en_GB", "lt_LT"].includes(TarkPay.locale)) {
                console.error("Invalid locale specified for TarkPay");
                return;
            }
            let locale = TarkPay.locale;
            if (window.localStorage && window.localStorage.getItem(TarkPay.payment_id + "-tp-locale") && !settingsObj.locale) {
                locale = window.localStorage.getItem(TarkPay.payment_id + "-tp-locale");
            }
            axios.defaults.headers["Client-ID"] = TarkPay.client_id;

            store.dispatch("fetchTranslations", locale).then(() => {
                axios.defaults.headers["Client-ID"] = TarkPay.client_id;
                injectTarkpayContainer();
                initTarkpayApp();

                if (!TarkPay.is_modal) {
                    open();
                } else {
                    let btn: HTMLElement | null = document.getElementById(settingsObj.btn_id);
                    if (btn) {
                        btn.onclick = function () {
                            open();
                        };
                    }

                }
            });
        });
    };

    TarkPay.translate = function (key: string) {
        // @ts-ignore
        if (store.state.translations && store.state.translations[key]) {
            // @ts-ignore
            return store.state.translations[key];
        }
        return key;
    };

    function initTarkpayApp() {
        let container_id = "#tarkpay-embed-app";
        if (TarkPay.container_id) {
            container_id = "#" + TarkPay.container_id;
        }
        if (TarkPay.redirect) {
            // Add a div in the end of body
            TarkPay.vueapp = createApp(TarkPayRedirect).use(store);
            TarkPay.vueapp.use(store);
            TarkPay.vueapp.mount(container_id);
        } else if (TarkPay.bulk_payment) {
            TarkPay.vueapp = createApp(TarkPayBulkPaymentRedirect).use(store);
            TarkPay.vueapp.use(store);
            TarkPay.vueapp.mount(container_id);
        } else {
            /*TarkPay.vueapp = createApp({
            components: {
                "tarkpay-modal": TarkPayModal
            }
            }).use(store).mount(container_id);*/
            alert("Only redirect method is allowed at the moment.");
        }
    }

    function open() {
        // Add a modal
        //TarkPay.vueapp.commit("openModal");
        //console.log(TarkPay.vueapp);
        store.commit("openModal"); // TODO ::Siia Tarkpay põhiseks
        //TarkPay.vueapp.$store.commit("setTransactionStep", "loading-banks");
    }

// @ts-ignore
})((window.TarkPay = window.TarkPay || {}));
