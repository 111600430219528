import {mapState} from "vuex";

const initialColors = {
    backgroundColor: '#FFFFFF',
    bodyBackgroundColor: '#FFFFFF',
    borderColor: '#D1D4E3',
    headerAndFooterBackgroundColor: '#E8E9F1',
    headerTextColor: '#4A5995',
    bodyTextColor: '#36457A',
    footerTextColor: '#747FA4',
    selectTextColor: '#2B3D7F',
    hoveredBankBorderColor: '#4A5995'

}
export const uiConfigurationsMixin = {
    data() {
        return {
            colors: {
                ...initialColors
            },
        }
    },
    computed: {
        ...mapState(['uiConfigurations'])
    },
    watch: {
        uiConfigurations: {
            handler() {
                this.matchUiConfigurations()
            },
            deep: true
        }
    },
    methods: {
        matchUiConfigurations() {
            this.colors = {...initialColors};

            if (this.uiConfigurations.modal) {
                if (this.uiConfigurations.modal.background_color) {
                    this.colors.backgroundColor = this.uiConfigurations.modal.background_color;
                }

                if (this.uiConfigurations.modal.body_background_color) {
                    this.colors.bodyBackgroundColor = this.uiConfigurations.modal.body_background_color;
                }

                if (this.uiConfigurations.modal.border_color) {
                    this.colors.borderColor = this.uiConfigurations.modal.border_color;
                }

                if (this.uiConfigurations.modal.header_and_footer_background_color) {
                    this.colors.headerAndFooterBackgroundColor = this.uiConfigurations.modal.header_and_footer_background_color;
                }

                if (this.uiConfigurations.modal.header_text_color) {
                    this.colors.headerTextColor = this.uiConfigurations.modal.header_text_color;
                }

                if (this.uiConfigurations.modal.body_text_color) {
                    this.colors.bodyTextColor = this.uiConfigurations.modal.body_text_color;
                }

                if (this.uiConfigurations.modal.footer_text_color) {
                    this.colors.footerTextColor = this.uiConfigurations.modal.footer_text_color;
                }

                if (this.uiConfigurations.modal.select_text_color) {
                    this.colors.selectTextColor = this.uiConfigurations.modal.select_text_color;
                }

                if (this.uiConfigurations.modal.hovered_bank_border_color) {
                    this.colors.hoveredBankBorderColor = this.uiConfigurations.modal.hovered_bank_border_color;
                }

            }
        }
    }
}