<template>
    <svg width="12" height="19" viewBox="0 0 12 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.125 0.25H2.375C1.10938 0.25 0.125 1.26953 0.125 2.5V16C0.125 17.2656 1.10938 18.25 2.375 18.25H9.125C10.3555 18.25 11.375 17.2656 11.375 16V2.5C11.375 1.26953 10.3555 0.25 9.125 0.25ZM4.625 1.375H6.875V1.9375H4.625V1.375ZM10.25 16C10.25 16.6328 9.72266 17.125 9.125 17.125H2.375C1.74219 17.125 1.25 16.6328 1.25 16V2.5C1.25 1.90234 1.74219 1.375 2.375 1.375H3.5V1.9375C3.5 2.57031 3.99219 3.0625 4.625 3.0625H6.875C7.47266 3.0625 8 2.57031 8 1.9375V1.375H9.125C9.72266 1.375 10.25 1.90234 10.25 2.5V16ZM6.875 14.3125H4.625C4.30859 14.3125 4.0625 14.5938 4.0625 14.875C4.0625 15.1914 4.30859 15.4375 4.625 15.4375H6.875C7.15625 15.4375 7.4375 15.1914 7.4375 14.875C7.4375 14.5938 7.15625 14.3125 6.875 14.3125Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "icon-phone"
};
</script>

<style scoped></style>
