<template>
    <div class="asc-form">
        <div class="asc-form-icon" v-if="icon"><Component :is="`icon-${icon}`" /></div>
        <div class="a-form-wrap">
            <input
                :type="type ? type : 'text'"
                class="a-form-input"
                :id="inputId"
                :placeholder="placeholder"
                @input="handleInputChange"
                :value="value"
            />
            <label :for="inputId">{{ placeholder }}</label>
        </div>
    </div>
</template>

<script>
import IconPerson from "@/components/icons/icon-person.vue";
import IconLock from "@/components/icons/icon-lock.vue";
import IconPhone from "@/components/icons/icon-phone.vue";
export default {
    components: { IconPerson, IconLock, IconPhone },
    props: ["placeholder", "value", "inputId", "icon", "type"],
    name: "asc-input",
    methods: {
        handleInputChange(e) {
            this.$emit("inputChanged", e.target.value);
        }
    }
};
</script>

<style scoped lang="scss">
@import "../assets/scss/mixins";

.asc-form {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    border: solid 1px #d1d8de;
    align-items: center;
    background-color: white;
    @include border-radius(8px);
    &-icon {
        padding: 0 0 0 16px;
    }
}

// FORMS
.asc-form .a-form {
    &-wrap {
        width: 100%;
        position: relative;

        label {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 12px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #5c6975;
            height: 100%;
            pointer-events: none;
            transform-origin: 0 0;
            transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
        }

        .a-form-input {
            &::placeholder {
                color: transparent;
            }
        }
    }

    &-input {
        display: flex;
        outline: 0;
        padding: 18px 12px 0 0;
        border: 0;
        font-size: 15px;
        line-height: 15px;
        font-weight: 400;
        color: #233238;
        width: calc(100% - 12px);
        height: 54px;
        margin-left: 12px;

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            transform: scale(0.9) translateY(-10px) translateX(0);
        }
    }

    &-select {
        display: flex;
        border: 0;
        outline: 0;
        padding: 18px 12px 0 12px;
        border-bottom: solid 1px #5c6975;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 7.5px center;
        background-size: 16px 12px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        color: #000;
        width: 100%;
        height: 60px;
        appearance: none;

        ~ label {
            transform: scale(0.9) translateY(-10px) translateX(0);
        }
    }

    &-error {
        display: none;
        flex-direction: row;
        flex: 1;
        align-items: center;
        padding: 11px 12px;
        background: #fff0f1;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: #ff0000;

        i {
            font-size: 18px;
            margin-right: 12px;
        }
    }
}

.asc-form.has-error {
    border-color: #ff0000;
    svg {
        fill: red;
        color: red;
    }
}

// OPTIONS
.asc-form .a-option {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    border: solid 1px #5c6975;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include border-radius(10px);

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .radio {
            background-color: #ff0000;
            border-color: #ff0000;

            &:after {
                display: flex;
                background: #fff;
            }
        }
    }

    &:hover {
        .radio {
            &:after {
                display: flex;
                background: #5c6975;
            }
        }
    }

    .radio {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 18px;
        background: #fff;
        border: solid 1px #5c6975;
        width: 22px;
        height: 22px;
        @include border-radius(22px);

        &:after {
            content: "";
            display: none;
            background: #fff;
            width: 10px;
            height: 10px;
            @include border-radius(10px);
        }
    }

    &-content {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        padding: 14px 18px;
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;

        strong {
            font-size: 17px;
            line-height: 20px;
            font-weight: 600;
            color: #000;
        }

        span {
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #000;
        }

        svg {
        }
    }

    &-price {
        display: flex;
        flex-direction: row;

        strong {
            margin-left: 12px;
            font-size: 24px;
            line-height: 27px;
            font-weight: 600;
            color: #000;
        }

        span {
            font-size: 16px;
            line-height: 27px;
            font-weight: 400;
            color: #000;
        }

        .color-red {
            color: #ff0000;
        }
    }
}
</style>
