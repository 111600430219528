export const swedbankConstants = {
    'PAYMENT_TYPE_REDIRECT': 'redirect',
    'PAYMENT_TYPE_BULK_PAYMENT': 'bulk-payment',
    'GET_ACCOUNTS': 'get_accounts',
    'ACCEPT_PAYMENT': 'accept_payment',
    'CONFIRM_PAYMENT': 'confirm_payment',
    'AUTHORIZATION': 'authorization',
    'SHOW_TEXT': 'show_text',
    'SUCCESSFUL': 'successful',
    'FAILED': 'failed',
    'COLOR': "#EE7028",
    'TEXTS': {
        'VERIFY_CODE': "Verify the code on your mobile phone",
        'ACCEPT_PAYMENT': "As a next step please control payment information and confirm it",
    }
};
export const lhvConstants = {
    'PAYMENT_TYPE_REDIRECT': 'redirect',
    'PAYMENT_TYPE_BULK_PAYMENT': 'bulk-payment',
    'SELECT_ROLE': 'select_role',
    'GET_ACCOUNTS': 'get_accounts',
    'ACCEPT_PAYMENT': 'accept_payment',
    'CONFIRM_PAYMENT': 'confirm_payment',
    'AUTHORIZATION': 'authorization',
    'SHOW_TEXT': 'show_text',
    'SHOW_ROLES': 'show_roles',
    'SUCCESSFUL': 'successful',
    'FAILED': 'failed',
    'COLOR': "#222221",
    'TEXTS': {
        'VERIFY_CODE': "Verify the code on your mobile phone",
        'ACCEPT_PAYMENT': "As a next step please control payment information and confirm it",
        'SELECT_ROLE': "As a next step please select debtor account from below",
    }
};
