<template>
    <svg width="16" height="19" viewBox="0 0 16 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.875 15.0156C8.26172 15.0156 8.57812 14.6992 8.57812 14.3125V12.0625C8.57812 11.6758 8.26172 11.3594 7.875 11.3594C7.48828 11.3594 7.17188 11.6758 7.17188 12.0625V14.3125C7.17188 14.6992 7.48828 15.0156 7.875 15.0156ZM15.75 9.8125C15.75 8.89844 14.9766 8.125 14.0625 8.125H13.5V5.94531C13.5 2.85156 10.9688 0.285156 7.875 0.25C4.78125 0.25 2.25 2.78125 2.25 5.875V8.125H1.6875C0.738281 8.125 0 8.89844 0 9.8125V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H14.0625C14.9766 18.25 15.75 17.5117 15.75 16.5625V9.8125ZM3.375 8.125V5.875C3.375 3.41406 5.37891 1.375 7.875 1.375C10.3359 1.375 12.375 3.41406 12.375 5.875V8.125H3.375ZM14.625 16.5625C14.625 16.8789 14.3438 17.125 14.0625 17.125H1.6875C1.37109 17.125 1.125 16.8789 1.125 16.5625V9.8125C1.125 9.53125 1.37109 9.25 1.6875 9.25H14.0625C14.3438 9.25 14.625 9.53125 14.625 9.8125V16.5625Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "icon-lock"
};
</script>

<style scoped></style>
