<template>
    <svg width="16" height="19" viewBox="0 0 16 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.0039 10.375C9.98438 10.375 9.52734 10.9375 7.875 10.9375C6.1875 10.9375 5.73047 10.375 4.71094 10.375C2.10938 10.375 0 12.5195 0 15.1211V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H14.0625C14.9766 18.25 15.75 17.5117 15.75 16.5625V15.1211C15.75 12.5195 13.6055 10.375 11.0039 10.375ZM14.625 16.5625C14.625 16.8789 14.3438 17.125 14.0625 17.125H1.6875C1.37109 17.125 1.125 16.8789 1.125 16.5625V15.1211C1.125 13.1172 2.70703 11.5 4.71094 11.5C5.41406 11.5 6.08203 12.0625 7.875 12.0625C9.63281 12.0625 10.3359 11.5 11.0039 11.5C13.0078 11.5 14.625 13.1172 14.625 15.1211V16.5625ZM7.875 9.25C10.3359 9.25 12.375 7.24609 12.375 4.75C12.375 2.28906 10.3359 0.25 7.875 0.25C5.37891 0.25 3.375 2.28906 3.375 4.75C3.375 7.24609 5.37891 9.25 7.875 9.25ZM7.875 1.375C9.70312 1.375 11.25 2.92188 11.25 4.75C11.25 6.61328 9.70312 8.125 7.875 8.125C6.01172 8.125 4.5 6.61328 4.5 4.75C4.5 2.92188 6.01172 1.375 7.875 1.375Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "icon-person"
};
</script>

<style scoped></style>
