<template>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28 0C12.4688 0 0 12.5781 0 28C0 43.5312 12.4688 56 28 56C43.4219 56 56 43.5312 56 28C56 12.5781 43.4219 0 28 0ZM28 52.5C14.4375 52.5 3.5 41.5625 3.5 28C3.5 14.5469 14.4375 3.5 28 3.5C41.4531 3.5 52.5 14.5469 52.5 28C52.5 41.5625 41.4531 52.5 28 52.5ZM28 33.25C28.875 33.25 29.75 32.4844 29.75 31.5V14C29.75 13.125 28.875 12.25 28 12.25C27.0156 12.25 26.25 13.125 26.25 14V31.5C26.25 32.4844 27.0156 33.25 28 33.25ZM28 37.625C26.4688 37.625 25.375 38.8281 25.375 40.25C25.375 41.7812 26.4688 42.875 28 42.875C29.4219 42.875 30.625 41.7812 30.625 40.25C30.625 38.8281 29.4219 37.625 28 37.625Z"
            fill="#E53935"
        />
    </svg>
</template>

<script>
export default {
    name: "icon-failed"
};
</script>

<style scoped></style>
