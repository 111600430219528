<template>
    <div class="asc-component-information">
        <p>
            <slot/>
        </p>

        <div class="asc-component-information-accounts">
            <div class="asc-component-information-accounts__text">{{
                    roles.length > 1 ? t("Choose a role") : t("Payment will be made from the following role")
                }}:
            </div>
            <select id="accountSelect" v-if="roles.length > 1" v-model="selRole"
                    class="asc-component-information-accounts__select" :style="{'border-color': color ? color : null}">
                <option v-for="role in roles"
                        :key="`id_${role.id}`"
                        :value="role.id">
                    {{ getRoleName(role) || '-' }}
                </option>
            </select>
            <div v-else>{{ getRoleName(roles[0]) }}</div>
        </div>
    </div>
</template>
<script>
import {translateMixin} from "@/mixins/translateMixin";
import {mapMutations, mapState} from "vuex";

export default {
    name: "lhv-select-role",
    mixins: [translateMixin],
    data() {
        return {
        }
    },
    props: {
        color: {
            default: null,
            type: String
        },
    },
    computed: {
        ...mapState('lhv', ['roles', 'selectedRole']),
        selRole: {
            get() {
                return this.selectedRole;
            },
            set(newValue) {
                return this.setSelectedRole(newValue);
            },
        },
    },
    methods: {
        ...mapMutations('lhv', ['setSelectedRole']),
        getRoleName(role) {
            let name = '';

            if (!role) return name;

            if (role.name) {
                name = `${role.name}`
            }

            if (role.id) {
                name += ` (${role.id})`
            }

            return name
        }
    }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.asc-component-information {
    width: 100%;

    &--code {
        font-weight: 700;
        font-size: 54px;
        line-height: 62px;
        color: $titleTextColor;
        margin: 0;
    }

    &-payment-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-row {
            display: flex;
            flex-direction: row;
        }

        &-col {
            padding: 6px 0;

            &:first-child {
                text-align: right;
                padding-right: 16px;
                min-width: 160px;
            }

            &:last-child {
                word-wrap: anywhere;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    &-accounts {
        margin-top: 24px;

        &__text {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;
        }

        &__select {
            margin-bottom: 18px;
            display: block;
            width: 100%;
            padding: 9px 36px 9px 14px;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            color: $defaultTextColor;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 16px 12px;
            border: 1px solid $defaultTextColor;
            appearance: none;
            @include border-radius(6px);
        }
    }
}

@media only screen and (max-width: $smallMobileMQ) {

}
</style>
