import axios from 'axios';
import { swedbankConstants as sc } from '@/constants';
import {types} from "sass";
import { Commit } from "vuex";
import List = types.List;

// Commit and State
export interface CommitFunction {
    commit: Commit;
}

export interface CommitStateFunction<T> extends CommitFunction {
    state: T;
}

const initialUser = {
    username: null,
    ssn: null,
    phone: '+372',
    errors: {},
};

// initialState
interface initialState {
    loading: boolean;
    step: string | null;
    user: any;
    authMethod: string | null;
    ibans: null | any;
    code: string | null;
    text: string | null;
    subStep: string | null;
    authMethods: any;
    payment: object;
    accounts: any;
    selectedAccount: string | null;
    paymentType: string;
}

let initialState = {
    loading: false,
    step: null,
    user: {...initialUser},
    authMethod: null,
    code: null,
    text: null,
    subStep: null,
    payment: {},
    accounts: [],
    selectedAccount: null,
    paymentType: 'redirect',
    authMethods: [
        {
            name: 'Mobile ID',
            value: 'MOBILE_ID',
        },
        {
            name: 'Smart ID',
            value: 'SMART_ID',
        },
        {
            name: 'ID Card',
            value: 'ID_CARD',
        },
    ],
};

export default {
    namespaced: true,
    state: {...initialState},
    actions: {
        // @ts-ignore
        async startSwedbankAuthorization({commit, state, rootState}: CommitStateFunction<initialState>, {swift} : {swift: string}) {
            await commit('setLoading', true);
            let isSuccessful = false;
            try {
                const payload = {
                    bic: swift,
                    method: state.authMethod,
                    username: state.user.username,
                    ssn: state.authMethod === 'SMART_ID' ? state.user.ssn : null,
                    mobile_no: state.authMethod === 'MOBILE_ID' ? state.user.phone : null,
                    // @ts-ignore
                    payment_key: window.TarkPay.payment_id,
                    // @ts-ignore
                    client_id: window.TarkPay.client_id,
                };
                if (rootState.jwt) {
                    axios.defaults.headers["X-Jwt"] = rootState.jwt;
                }

                let response = await axios.post(`${process.env.VUE_APP_AS_API_URL}/api/v1/psd2/embed/${state.paymentType}/swedbank/authorize/`, payload, {
                    params: {
                        // @ts-ignore
                        payment_key: window.TarkPay.payment_id,
                        // @ts-ignore
                        client_id: window.TarkPay.client_id,
                        payment_type: state.paymentType
                    },

                })

                if (response.headers["x-jwt"]) {
                    commit("setJwt", response.headers["x-jwt"], {root: true});
                }

                if (response.status === 200 && response.data.code) {
                    const data = response.data;
                    await commit('setCode', data.code);
                    await commit('setText', sc.TEXTS.VERIFY_CODE);
                    await commit('setStep', sc.SHOW_TEXT);
                    await commit('setSubStep', sc.AUTHORIZATION);
                    isSuccessful = true;
                } else {
                    let hasKnownError = false;
                    if (response.data.error) {
                        const returnedError = response.data.error;
                        if (['USER_ID_NOT_VALID', 'USER_NOT_FOUND'].includes(returnedError)) {
                            hasKnownError = true;
                            await commit('setUserError', {prop: 'username', value: true});
                        } else if (['USER_NOT_VALID'].includes(returnedError)) {
                            hasKnownError = true;
                            await commit('setUserError', {prop: 'ssn', value: true});
                        }
                    }
                    if (!hasKnownError) {
                        await commit('setStep', sc.FAILED);
                    }
                }
            }
            catch (e) {
                console.log(e);
                await commit('setStep', sc.FAILED);
            }

            await commit('setLoading', false);
            return isSuccessful;
        },

        //@ts-ignore
        async pingSwedbankAuthorization({commit, state, rootState}: CommitStateFunction<initialState>,  {swift}: {swift: string}) {
            let isSuccessful = false;
            try {

                if (rootState.jwt) {
                    axios.defaults.headers["X-Jwt"] = rootState.jwt;
                }

                let response = await axios.get(`${process.env.VUE_APP_AS_API_URL}/api/v1/psd2/embed/${state.paymentType}/swedbank/authorize/`, {
                    params: {
                        method: state.authMethod,
                        // @ts-ignore
                        payment_key: window.TarkPay.payment_id,
                        // @ts-ignore
                        client_id: window.TarkPay.client_id,
                        payment_type: state.paymentType
                    },

                })

                if (response.headers["x-jwt"]) {
                    commit("setJwt", response.headers["x-jwt"], {root: true});
                }
                if (!response || response.status !== 200) {
                    await commit('setStep', sc.FAILED);
                } else if (response.data && response.data.success === 1 && response.data.status === 'finalised') {
                    isSuccessful = true;
                    await commit('setText', sc.TEXTS.ACCEPT_PAYMENT);
                    await commit('setCode', null);
                    await commit('setSubStep', sc.ACCEPT_PAYMENT);
                    await commit('setAccounts', response.data.accounts);
                } else {
                    if (!response.data || !response.data.success) {
                        await commit('setStep', sc.FAILED);
                    }
                }
            }
            catch (e) {
                console.log(e);
                await commit('setStep', sc.FAILED);
            }

            await commit('setLoading', false);
            return isSuccessful;
        },
        async startPaymentAuthorization({commit, state}: CommitStateFunction<initialState>, {swift} : {swift: string}) {
            await commit('setLoading', true);
            let isSuccessful = false;
            try {
                const payload = {
                    bic: swift,
                    selected_iban: state.selectedAccount,
                    // @ts-ignore
                    payment_key: window.TarkPay.payment_id,
                    // @ts-ignore
                    client_id: window.TarkPay.client_id,
                };

                let response = await axios.post(`${process.env.VUE_APP_AS_API_URL}/api/v1/psd2/embed/${state.paymentType}/swedbank/payments/`, payload, {
                    params:
                        {
                            selected_iban: state.selectedAccount,
                            // @ts-ignore
                            payment_key: window.TarkPay.payment_id,
                            // @ts-ignore
                            client_id: window.TarkPay.client_id,
                            payment_type: state.paymentType
                        },
                });
                if (response.status === 200 && response.data && response.data.success === 1) {
                    const data = response.data;
                    await commit('setCode', data.code);
                    await commit('setText', sc.TEXTS.VERIFY_CODE);
                    await commit('setStep', sc.SHOW_TEXT);
                    await commit('setSubStep', sc.CONFIRM_PAYMENT);
                    isSuccessful = true;
                } else {
                    await commit('setStep', sc.FAILED);
                }
            } catch (e) {
                console.error(e)
                if (e.message !== 'Network Error' && e.message !== 'The network connection was lost.') {
                    await commit('setStep', sc.FAILED);
                }
            }
            await commit('setLoading', false);
            return isSuccessful;
        },
        async pingPaymentAuthorization({commit, state}: CommitStateFunction<initialState>, {swift} : {swift: string}) {
            let isSuccessful = false;
            try {
                let response = await axios.get(`${process.env.VUE_APP_AS_API_URL}/api/v1/psd2/embed/${state.paymentType}/swedbank/payments/`, {
                    params:
                        {
                            // @ts-ignore
                            payment_key: window.TarkPay.payment_id,
                            // @ts-ignore
                            client_id: window.TarkPay.client_id,
                            payment_type: state.paymentType
                        },
                });
                if (!response || response.status !== 200) {
                    await commit('setStep', sc.FAILED);
                } else if (response.data && response.data.success === 1 && response.data.status === 'finalised') {
                    isSuccessful = true;
                    await commit('setCode', null);
                    await commit('setSubStep', null);
                    await commit('setText', null);
                    await commit('setStep', sc.SUCCESSFUL);
                } else {
                    if (!response.data || !response.data.success) {
                        await commit('setStep', sc.FAILED);
                    }
                }
            } catch (e) {
                console.log(e);
                if (e.message !== 'Network Error' && e.message !== 'The network connection was lost.') {
                    await commit('setStep', sc.FAILED);
                }
            }
            await commit('setLoading', false);

            if (isSuccessful) {
                await commit('setLoading', true);
            }
            return isSuccessful;
        },
    },
    mutations: {
        setPaymentType(state: initialState, paymentType: string) {
            state.paymentType = paymentType
        },
        addPaymentInfo(state: initialState, payment: any) {
            state.payment = payment
        },
        setLoading(state: initialState, bool: boolean) {
            state.loading = bool;
        },
        setUser(state: initialState, {prop, value}: any) {
            state.user[prop] = value;
        },
        setUserError(state: initialState, {prop, value}: any) {
            state.user.errors[prop] = value;
        },
        setStep(state: initialState, value: any) {
            state.step = value;
            if (value === sc.FAILED) {
                state.text = initialState.text;
                state.code = initialState.code;
                state.accounts = initialState.accounts;
                state.subStep = initialState.subStep;
            }
        },
        setAuthMethod(state: initialState, value: any) {
            state.authMethod = value;
        },
        setCode(state: initialState, value: any) {
            state.code = value;
        },
        setText(state: initialState, value: any) {
            state.text = value;
        },
        setSubStep(state: initialState, value: any) {
            state.subStep = value;
        },
        resetStates(state: initialState) {
            state.step = initialState.step;
            state.code = initialState.code;
            state.subStep = initialState.subStep;
            state.accounts = initialState.accounts;
            state.selectedAccount = initialState.selectedAccount;
            state.authMethod = initialState.authMethod;
        },
        setAccounts(state: initialState, value: any) {
            state.accounts = value;
            state.selectedAccount = state.accounts.length > 0 ? state.accounts[0].iban : null;
        },
        setSelectedAccount(state: initialState, value: string) {
            state.selectedAccount = value;
        }

    },
    getters: {},
};
