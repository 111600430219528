<template>
    <div class="asc-components-failed">
        <div>
            <icon-failed width="40px" />
        </div>
        <h1>{{ t("There was an error!") }}</h1>
        <p>
            {{ t("Please try again") }}!
        </p>
    </div>
</template>
<script>
import IconFailed from "../../components/icons/icon-failed.vue";
import {translateMixin} from "@/mixins/translateMixin";

export default {
    name: "content-failed",
    components: { IconFailed },
    mixins: [translateMixin]
};
</script>
<style scoped lang="scss">
.asc-components-failed {
    width: 100%;

    h1 {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #233238;
    }

    p {
        font-size: 15px;
        line-height: 19px;
        color: #233238;
    }
    svg {
        fill: green;
    }
}
</style>
